import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

import blueUserIcon from "./blue-user-icon1.svg";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
} from "reactstrap";

import MyAccount from "../../../../components/MyAccount";
import "./userbox.css";
import logout from "../../../../utils/logout";



const UserBox = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [orgPic, setOrgPic] = useState({ status: "not-triggered", path: "" });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const message = useSelector((state) =>
    _.get(state, "my_profile.my_org.message")
  );
  const alertMessage = message && t(message[0].I18_code);
  const alertStyle = message && `alert-${message[0].name}`;
  const myProfile = useSelector((state) =>
    _.get(state, "my_profile.profileData")
  );
  const myOrg = useSelector((state) => _.get(state, "my_profile.my_org"));
  const pageConfig = useSelector((state) =>
    _.get(state, "my_profile.page_config", {})
  );
  const config = useSelector((state) => state.config.config);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const closeDropdown = () => setDropdownOpen(false);

  const getDefaultOrgImg = () => {
    let showDefaultCwLogo = _.get(
      pageConfig,
      "header.show_default_cw_logo",
      false
    );
    return showDefaultCwLogo ? `/cdn/assets/images/Your_logo_here.svg` : "";
  };

  const loadImageURL = async (path, defImg) => {
    try {
      let dupObj = {
        status: "loading",
        path: "",
      };
      if (path) {
        dupObj.path = path;
      } else {
        dupObj.path = defImg;
      }
      dupObj.status = "done";
      setOrgPic(dupObj);
    } catch (e) {
      setOrgPic({
        status: "done",
        path: defImg,
      });
    }
  };

  useEffect(() => {
    let logoUrl = _.get(myOrg, "logo_url");
    let defOrg = getDefaultOrgImg();
    if (logoUrl && isValidHttpUrl(logoUrl)) {
      loadImageURL(logoUrl, defOrg);
    } else {
      setOrgPic({
        status: "done",
        path: defOrg,
      });
    }
  }, [myOrg]);

  const isValidHttpUrl = (string) => {
    try {
      let url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (e) {
      return false;
    }
  };

  const profilePic = () => {
    let profilePic = _.get(myProfile, "profile_pic");
    return profilePic && isValidHttpUrl(profilePic) ? profilePic : blueUserIcon;
  };

  const name = _.get(myProfile, "name");
  const company = _.get(myProfile, "organization.name");
  const updatedAt = _.get(myProfile, "updated_at");
  const ORG_LOGO = _.get(config, "ORG_LOGO");
  const copyrightYear = new Date().getFullYear();

  return (
    <div className="mb-2 me-2 mt-2">
      <div className="p-0 me-1 ml-1">
        <div className="border rounded p-1">
          <UncontrolledButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
          >
            <DropdownToggle color="" className="p-0">
              <div className="d-none d-md-block">
                <div className="d-flex ">
                  <img
                    width={35}
                    className="rounded-circle"
                    src={profilePic()}
                    alt=""
                  />
                  <div className="d-none d-xl-block d-lg-block d-md-block ms-2">
                    {ORG_LOGO && isValidHttpUrl(ORG_LOGO) ? (
                      <img src={ORG_LOGO} width={78} height={32} />
                    ) : (
                      orgPic.status === "done" &&
                      orgPic.path && (
                        <img src={orgPic.path} width={78} height={32} />
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                <img
                  width={35}
                  className="rounded-circle m-2"
                  src={profilePic()}
                  alt=""
                />
              </div>

              {/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                99+
                <span class="visually-hidden">unread messages</span>
              </span> */}
              {message && (
                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span class="visually-hidden">New alerts</span>
                </span>
              )}
            </DropdownToggle>
            {config && (
              <DropdownMenu className="userBox-dropdown">
                <div className="bg-white">
                  <div>
                    <div className="text-left">
                      <div className="userBox-Card mb-2">
                        <div className="row p-3">
                          <div
                            className={`userBox-col-sm-${
                              config.PRIVACY || config.COOKIE || config.API
                                ? "7"
                                : "12"
                            }`}
                          >
                            <div
                              className={`userBox-widget-content p-0 ${
                                config.PRIVACY || config.COOKIE || config.API
                                  ? "border-end"
                                  : ""
                              }`}
                            >
                              <div className="userBox-Widget-Content-Wrapper">
                                <div className="me-3">
                                  <img
                                    width="35"
                                    className="rounded-circle"
                                    src={profilePic()}
                                    alt=""
                                  />
                                </div>
                                <div className="userBox-Heading pt-2 pb-1 mt-1">
                                  {name}
                                </div>
                                <div className="userBox-subHeading td-width">
                                  {company}
                                </div>
                                <ul className="nav flex-column mt-2">
                                  <li
                                    className="nav-item-btn text-left nav-item"
                                    onClick={closeDropdown}
                                  >
                                    <MyAccount />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          {(config.PRIVACY || config.COOKIE || config.API) && (
                            <div className="userBox-col-sm-5 pt-4">
                              {config.PRIVACY && (
                                <div className="mt-1">
                                  <a
                                    href={config.PRIVACY}
                                    className="UserBox-AnchorName"
                                  >
                                    Privacy policy
                                  </a>
                                </div>
                              )}
                              {config.COOKIE && (
                                <div className="mt-1">
                                  <a
                                    href={config.COOKIE}
                                    className="UserBox-AnchorName"
                                  >
                                    Cookie Policy
                                  </a>
                                </div>
                              )}
                              {config.API && (
                                <div className="mt-1">
                                  <a
                                    href={config.API}
                                    className="UserBox-AnchorName"
                                  >
                                    API Terms of Use
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                          {message && (
                            <div
                              className={`alert ${alertStyle} alert-dismissible fade show`}
                              role="alert"
                            >
                              {alertMessage}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="UserBox-LastContainer">
                    <div>
                      <div>
                        <h1 className="UserBox-LastContainerSubHeading">
                          Last login:{" "}
                          <span style={{ color: "#ADB5BD" }}>
                            {moment(updatedAt).format(
                              "ddd MMM DD YYYY | HH:mm"
                            )}
                          </span>
                        </h1>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn-pill btn-shadow btn-shine btn btn-focus"
                      onClick={() => logout(config)}
                    >
                      <i className="sign-out-primary"></i>
                    </button>
                  </div>
                  <div className="Mobile-signout userBox-signout">
                    <button
                      type="button"
                      className="btn-pill btn-shadow btn-shine btn btn-focus"
                      onClick={() => logout(config)}
                    >
                      <i className="sign-out-primary"></i>
                    </button>
                  </div>
                </div>
              </DropdownMenu>
            )}
          </UncontrolledButtonDropdown>
        </div>
      </div>
    </div>
  );
};

export default UserBox;
